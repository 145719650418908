import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import {CART, HEADER} from "./constants/contexts";
import classNames from "classnames/bind";
import "./styles/CartTotals.scss";
import {getCartSession} from "services/store/index";
import vintageAxios from "services/api/index";

class CartTotals extends Component {
  fetchTotals(){
    const {shippingAddress, zipCode} = this.props;
    let data = {},
        url = `/management/shopping-carts/${getCartSession()}/totals/`;
    if( shippingAddress )
      data["shipping_address"] = shippingAddress.id;
    if( zipCode )
      url = `/management/shopping-carts/${getCartSession()}/totals/zipcode/${zipCode}/`;
    const promise = vintageAxios.post(url, data);
    promise.then(response => {
      this.props.receiveCartTotals(response.data);
    });
    return promise;
  }

  componentDidMount(){
    this.fetchTotals();
  }

  debounceFetchTotals = debounce(this.fetchTotals, 500);

  componentDidUpdate(prevProps, prevState){
    if(
        prevProps.store.cart !== this.props.store.cart  // Cart items or quantities changed
        || prevProps.shippingAddress !== this.props.shippingAddress  // Shipping address in checkout changed
        || prevProps.zipCode !== this.props.zipCode  // Zip code in cart changed
    )
      this.debounceFetchTotals();
  }

  render(){
    const {cartTotals} = this.props.store;
    const {context} = this.props;

    if( !cartTotals.total )  // Object is empty
      return <div>Loading...</div>;

    return(
        <table className={classNames({
          "table shopping-cart-totals": true,
          "shopping-cart-totals--header": context === HEADER
        })}>
          <colgroup>
            <col className="col-md-6"/>
            <col className="col-md-6"/>
          </colgroup>
          {
            context !== HEADER && (
              <tbody>
                <tr>
                  <td>Subtotal</td>
                  <td className="right">${cartTotals.sub_total}</td>
                </tr>
                <tr>
                  <td>Shipping</td>
                  <td className="right">${cartTotals.shipping_fee}</td>
                </tr>
                {
                  cartTotals.promotions_applied.map((discount, index) => (
                    <tr
                        key={`extra-discount-${index}`}
                        className="shipping-cart-totals__promotion">
                      <td>{discount.name}</td>
                      <td className="right">-${discount.total}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td>Taxes</td>
                  <td className="right">${cartTotals.sale_tax}</td>
                </tr>
              </tbody>
            )
          }
          {
            context === HEADER ? (
                <tbody>
                <tr className="total">
                  <td>SUB TOTAL</td>
                  <td className="right">${cartTotals.sub_total}</td>
                </tr>
                </tbody>
            ) : (
                <tbody>
                <tr className="total">
                  <td>TOTAL</td>
                  <td className="right">${cartTotals.total}</td>
                </tr>
                </tbody>
            )
          }
        </table>
    )
  }
}

CartTotals.propTypes = {
  context: function(props, propName, componentName){
    // At least by now this button could live in ProductDetails and WishList components
    const availableContexts = [
      CART,
      HEADER
    ];
    if( availableContexts.indexOf(props[propName]) === -1 ){
      return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. Validation failed.`
      );
    }
  },
};

CartTotals.propTypes = {
  shippingAddress: PropTypes.object,
  zipCode: PropTypes.string
};

CartTotals.defaultPropTypes = {
  shippingAddress: null,
  zipCode: null
};

export default CartTotals
