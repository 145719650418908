import React, {Component} from 'react';
import PropTypes from 'prop-types';
import vintageAxios from "services/api/index";
import {
  DEFAULT_BY_SHIPPING
} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";
import classNames from "classnames/bind";

class Address extends Component {
  // TODO look for away to avoid this repeated code in Address and CheckoutAddress components
  constructor(props) {
    super(props);

    this.state = {
      settingAsDefault: false,
      dropdownByDefaultOpen: false
    };

    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
    this.editAddress = this.editAddress.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
  }

  editAddress() {
    const {address, openAddressModal, selectAddress} = this.props;
    openAddressModal();
    selectAddress(address);
  }

  deleteAddress() {
    const {address} = this.props;
    this.props.deleteAddress(address)
  }

  /**
   * Set address book as default in the API and update state.
   * @returns {AxiosPromise}
   */
  setAsDefault(byDefaultAddress) {
    const {address} = this.props;
    const promise = Address.setAddressAsDefault(address, byDefaultAddress);
    promise.then(response => {
      this.props.setAddressBookAsDefault(response.data);
      this.setState({
        settingAsDefault: false
      });
    });
    return promise;
  }

  /**
   * Set address book as default in the API.
   * @param address
   * @param byDefaultAddress
   * @param isByDefault
   * @return {AxiosPromise<any>}
   */
  static setAddressAsDefault(address, byDefaultAddress, isByDefault = true) {
    if (isByDefault)
      address["address_type"] = byDefaultAddress;
    const promise = vintageAxios.patch(`/v2/management/customers/addressess/${address.uuid}/`, {
      ...address,
      default: isByDefault
    });

    return promise;
  }

  /**
   * Handles clicks on the set as default button.
   */
  handleSetAsDefaultClick() {
    if (!this.state.settingAsDefault)
      this.setState({
        settingAsDefault: true
      }, () => {
        this.setAsDefault(DEFAULT_BY_SHIPPING);
      });
  }

  static parsePhoneNumberFormat(phoneNumber) {
    let cleanedNumber = phoneNumber.replace(/\D/g, "");
    return `(${cleanedNumber.slice(0, 3)}) ${cleanedNumber.slice(3, 6)}-${cleanedNumber.slice(6)}`
  }

  render() {
    const {settingAsDefault} = this.state;
    const {address} = this.props;

    return (
        <div className={classNames({
          "address-item": true,
          "address-item--default": address.default
          && address.address_type === DEFAULT_BY_SHIPPING
        })}>
          <div className="address-item__content">
            <div className="row">
              <div className="col-sm-6">
                <p>{address.full_name}</p>
              </div>
              <div className="col-sm-6 address-item__by-default">
                {
                  (address.default && address.address_type === DEFAULT_BY_SHIPPING) &&
                  <p className="address-item__by-default__shipping">
                    default for shipping
                  </p>
                }
              </div>
            </div>
            <div className="address-item__content__address">
              <h3>Address</h3>
              <p>
                {address.address_1}{`${address.address_2 ? ', ' + address.address_2 : ''}`}
                <br/>
                {address.city_name}, {address.state_code} {address.zip_code}
                <br/>
                {Address.parsePhoneNumberFormat(address.phone)}
              </p>
            </div>
            <div className="address-item__content__email">
              {/* TODO api end-point doesn't receive phone */}
              <h3>Email</h3>
              <p>{address.email}</p>
            </div>
          </div>
          <hr/>
          <div className="address-item__actions">
            <div className="address-item__actions__edit address-item__actions__hover">
              <span onClick={this.editAddress}>
                <i className="fa fa-pencil"/>Edit
              </span>
            </div>
            <div className={classNames({
              "address-item__actions__delete": true,
              "address-item__actions__delete--bar-right": !address.default
              || (address.default && address.address_type !== DEFAULT_BY_SHIPPING),
              "address-item__actions__hover": true
            })}>
              <span onClick={this.deleteAddress}>
                <i className="fa fa-trash"/>Delete
              </span>
            </div>
            <div className="address-item__actions__status">
              {
                settingAsDefault ? (
                    <span
                        className="address-item__actions__status__setting">Setting...</span>
                ) : (
                    (!address.default
                        || (address.default && address.address_type !== DEFAULT_BY_SHIPPING)) && (
                        <span
                            className="address-item__actions__hover address-item__actions__status__set-as-default"
                            onClick={this.handleSetAsDefaultClick}>
                          <i className="fa fa-check-square"/>Set as default for shipping
                        </span>
                    )
                )
              }
            </div>
          </div>
        </div>
    )
  }
}

Address.propTypes = {
  address: PropTypes.object.isRequired
};

export default Address