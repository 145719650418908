import React, {Component} from 'react';
import vintageAxios from "services/api";
import {QUERY_PARAM_PAGE} from "modules/store/scenes/ProductsList/constants/query-params";
import {getParsedQueryParams} from "services/browser-history/index";
import LoadingCassette from "components/LoadingCassette/index";
import {MAX_RECENTLY_VIEWED_FOR_TRANSITION} from "components/Header/components/RecentlyViewedDropdown/constants/index";
import classNames from 'classnames/bind';
import onClickOutside from "react-onclickoutside";
import {Link} from "react-router-dom";
import "./styles/RecentlyViewedDropdown.scss";
import Product from "modules/store/scenes/ProductsList/components/Product";

export class RecentlyViewedDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      hasPrevious: false,
      hasNext: false,
      products: null,
      productsToShow: null,
      recentlyViewedDropdownIsOpen: false
    };

    this.handleToogleRecentlyViewed = this.handleToogleRecentlyViewed.bind(this);
    this.goToPrevious = this.goToPrevious.bind(this);
    this.goToNext = this.goToNext.bind(this);
  }

  /**
   * Get products recently viewed from the API
   * @returns {AxiosPromise}
   */
  fetchProductsRecentlyViewed() {
    const promise = vintageAxios.get('/products/all/?page_size=4&view_type=recently-viewed', {
      params: {
        page: getParsedQueryParams()[QUERY_PARAM_PAGE]
      }
    });

    return promise;
  }

  /**
   * Handle toggle on recently viewed
   */
  handleToogleRecentlyViewed() {
    const {recentlyViewedDropdownIsOpen} = this.state;

    this.setState({
      recentlyViewedDropdownIsOpen: !recentlyViewedDropdownIsOpen
    }, () => {
      if (this.state.recentlyViewedDropdownIsOpen) {
        const {products} = this.state;

        if (!products)
          this.fetchProductsRecentlyViewed().then(response => {
            this.setState({
              hasPrevious: false,
              products: response.data.results,
              productsToShow: [...response.data.results].splice(
                  this.state.index, MAX_RECENTLY_VIEWED_FOR_TRANSITION)
            }, () => {
              this.setState({hasNext: this.hasNext});
            });
          });
        else
          this.resetProductsOfDropdown();
      }
    });
  }

  /**
   * Establishes the initial state of the Dropdown
   */
  resetProductsOfDropdown() {
    this.setState({
      index: 0,
      hasPrevious: false,
      productsToShow: [...this.state.products].splice(
          0, MAX_RECENTLY_VIEWED_FOR_TRANSITION)
    });
  }

  /**
   * Verify if have next products to show
   * @return {Boolean}
   */
  get hasNext() {
    const {index} = this.state;
    const products = [...this.state.products];

    return products.length > (index + 1) * MAX_RECENTLY_VIEWED_FOR_TRANSITION;
  }

  /**
   * Verify if have previous products to show
   * @return {Boolean}
   */
  get hasPrevious() {
    const {index} = this.state;

    return index > 0;
  }

  /**
   * View previos products recently viewed
   */
  goToPrevious() {
    const {index} = this.state;
    const products = [...this.state.products];

    if (index > 0) {
      this.setState({
        index: index - 1,
        hasNext: true,
        productsToShow: products.splice(
            (index - 1) * MAX_RECENTLY_VIEWED_FOR_TRANSITION,
            MAX_RECENTLY_VIEWED_FOR_TRANSITION)
      }, () => {
        this.setState({hasPrevious: this.hasPrevious});
      });
    }
  }

  /**
   * View more products recently viewed
   */
  goToNext() {
    const {index} = this.state;
    const products = [...this.state.products];

    if (products.length > (index + 1) * MAX_RECENTLY_VIEWED_FOR_TRANSITION) {
      this.setState({
        index: index + 1,
        hasPrevious: true,
        productsToShow: products.splice(
            (index + 1) * MAX_RECENTLY_VIEWED_FOR_TRANSITION,
            MAX_RECENTLY_VIEWED_FOR_TRANSITION)
      }, () => {
        this.setState({hasNext: this.hasNext});
      });
    }
  }

  /**
   * Click outside box
   * @param event
   */
  handleClickOutside(event) {
    this.setState({
      recentlyViewedDropdownIsOpen: false
    });
  };

  render() {
    const {
      recentlyViewedDropdownIsOpen, productsToShow,
      hasPrevious, hasNext
    } = this.state;

    return (
        <div className="recently-viewed">
          <button
              onClick={this.handleToogleRecentlyViewed}
              className="recently-viewed__link un-styled">
            <span>RECENTLY VIEWED</span>
          </button>
          <div className={classNames({
            "recently-viewed__dropdown container": true,
            "recently-viewed__dropdown--is-open": recentlyViewedDropdownIsOpen
          })}>
            <div className="recently-viewed__dropdown__wrapper">
              <div className="recently-viewed__dropdown__wrapper__header">
                <span className="recently-viewed__dropdown__wrapper__header__title">
                  Recently Viewed
                </span>
                <Link to="/store/products?view_type=recently-viewed"
                      onClick={this.handleToogleRecentlyViewed}
                      className="recently-viewed__dropdown__wrapper__header__button-all">
                  <span className="btn-custom">View all</span>
                </Link>
              </div>
              <div className="recently-viewed__dropdown__wrapper__body">
                <ul className="recently-viewed__dropdown__wrapper__body__products-list">
                  {
                    productsToShow === null ? (
                        <LoadingCassette/>
                    ) : (
                        productsToShow.map(product => (
                            <li
                                key={`recently-viewed__dropdown__wrapper__body__products-list__item${product.id}`}
                                className="recently-viewed__dropdown__wrapper__body__products-list__item">
                              <Product product={product}/>
                            </li>
                        ))
                    )
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default onClickOutside(RecentlyViewedDropdown);
