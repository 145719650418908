import vintageAxios from 'services/api';
import {LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_USER_KEY} from "./constants";
import {removeCartSession} from "services/store";
import {removeWishList} from "services/store/wishlist";
import {
  removeAddressInLocalStorage,
  removeCreditCardInLocalStorage
} from "modules/store/scenes/CheckoutPage/services/storage";

export const login = (email, password, recaptcha) => {
  let data = {
    'email': email,
    'password': password,
    'recaptcha': recaptcha
  };
  let promise = vintageAxios.post('/auth/login/', data);
  promise.then(response => saveUserInformation(response));
  return promise;
};

export const resetPassword = (email) => {
  let data = {
    'email': email
  };
  return vintageAxios.post('/auth/forgot-password/', data);
};

export const changePassword = (data) => {
  return vintageAxios.post('/auth/update-password/', data);
};

export const signUp = (data) => {
  let promise = vintageAxios.post('/auth/signup/', data);
  promise.then(response => saveUserInformation(response));
  return promise;
};

export const saveToken = (token) => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const removeToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
};

export const isAuthenticated = () => {
  return getToken() !== null;
};

export const saveUser = (user) => {
  let propertiesToSave = [
    'first_name', 'last_name', 'email'
  ];
  let userToSave = {};
  propertiesToSave.forEach(propertyName => {
    userToSave[propertyName] = user[propertyName];
  });
  user = JSON.stringify(userToSave);
  localStorage.setItem(LOCAL_STORAGE_USER_KEY, user);
};

export const saveUserInformation = (response) => {
  logout();  // Perform a logout to make sure every old information is removed before saving the fresh info
  let user = response.data;
  saveToken(user.token);
  delete user.token;
  saveUser(user);
};

export const getUser = () => {
  let user = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
  return JSON.parse(user);
};

export const removeUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);
};

export const logout = () => {
  removeToken();
  removeUser();
  removeCartSession();
  removeWishList();
  removeCreditCardInLocalStorage();
  removeAddressInLocalStorage();

  // TODO is there something else to remove on here?
  // TODO What about doing localStorage.clear() on here?
};