import React, { Component } from 'react';
import {apiList} from "services/api";
import "./styles/HomeSlider.scss";
import Slider from "react-slick";

class HomeSlider extends Component {
  constructor(props){
    super(props);

    this.state = {
      slides: []
    };
  }

  fetchSiteSliders(){
    const promise = apiList('siteSliders');
    return promise.then(response => {
      this.setState({
        slides: response.data
      });
    });
  }

  componentDidMount(){
    this.fetchSiteSliders();
  }

  render(){
    const {slides} = this.state;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: slides.length > 1,
      autoplaySpeed: 5000,
      adaptiveHeight: true,
    };

    if (slides.length === 0) {
      return null;
    }

    return (
        <section className="home__slider carousel">
          <Slider {...settings}>
            {slides.map((slide) => (
                <div key={`homeSlide${slide.id}`} className="slide__item">
                  <a href={slide.url}>
                    <img
                        className="slide__img"
                        src={slide.image}
                        alt={slide.alt || 'Slide Image'} // Usa un texto alternativo dinámico
                    />
                  </a>
                </div>
            ))}
          </Slider>
        </section>
    )
  }
}

export default HomeSlider;
