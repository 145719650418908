import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Moment from "react-moment";
import "./styles/ProductGridView.scss";
import { ProductContext, ProductIcon } from '@vintagestock/vintage-common-components';
import WishlistHeartBtnContainer from "modules/store/components/WishlistHeartBtn/container";
import AddToCartButtonContainer from "modules/store/scenes/ProductDetails/components/AddToCartButton/container";
import NotifyMeWhenAvailableButton from "utils/components/NotifyMeWhenAvailableButton";
import PriceTable from "modules/store/components/PriceTable"
import {RADIO_ONLINE_NEW, RADIO_ONLINE_USED} from "modules/store/components/PriceTable/constants/radios"
import ProductAvailableIn from "modules/store/scenes/ProductsList/components/Product/components/ProductAvailableIn"
import FindInStoreBtn from "modules/store/components/FindInStoreBtn"
import ProductImage from "modules/store/scenes/ProductsList/components/Product/components/ProductImage"

export default function ProductGridView({productUrl, onClick}) {
  const {product, condition, quantity, activeRadio} = useContext(ProductContext);
  return (
      <div className="product-card">
        <div className="product-card__icon">
          <ProductIcon icon={product.icon}/>
        </div>
        <div className="product-card__wishlist-btn">
          <WishlistHeartBtnContainer/>
        </div>
        <ProductImage
            productName={product.name}
            productImageUrl={product.list_image_thumbnail}
            productUrl={productUrl}
            productIcon={product.icon}
            onClick={onClick}/>
        <div className="product-card-info">
          <h6 className="product-card-info__name">
            {product.name}
          </h6>
          {
            moment(product.release_date) > moment(new Date()) &&
            <p className="product-card-info__release-date">
              Release:&nbsp;
              <Moment format="MMM D, YYYY">
                {product.release_date}
              </Moment>
            </p>
          }
          {
            product.artist &&
            <p className="product-card-info__artist">
              {product.artist}
            </p>
          }
        </div>
        <div className="product-card-actions">
          <PriceTable/>
          {
            product.in_stock || product.pickup_available
                ?
                <React.Fragment>
                  {
                    (activeRadio === RADIO_ONLINE_NEW || activeRadio === RADIO_ONLINE_USED)
                        ?
                        <div className="product-card-actions__actions">
                          <AddToCartButtonContainer
                              contentTypeId={product.content_type}
                              productId={product.id}
                              quantity={quantity}
                              productData={{
                                sku: product.sku,
                                name: product.name
                              }}
                              condition={condition}
                              hideIcon={product.is_pre_order}
                              customLabel={product.is_pre_order ? "PRE-ORDER" : "Add to cart"}
                              size="small"/>
                        </div>
                        :
                        /* I've temporarily put the same class as the add to cart button because stylesheets of this project sucks */
                        <>
                          <ProductAvailableIn stores={product.stores}/>
                          <FindInStoreBtn
                              className="add-to-cart-button add-to-cart-button--style-button add-to-cart-button--size-small"/>
                        </>
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  {
                    product.is_pre_order &&
                    <NotifyMeWhenAvailableButton
                        condition={condition}
                        stockNew={product.total_stock_new}
                        stockUsed={product.total_stock_used}
                        productId={product.id}
                        onlyIcon/>
                  }
                </React.Fragment>
          }
        </div>
      </div>
  )
}

ProductGridView.propTypes = {
  productUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
