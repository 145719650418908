import * as types from '../actions/types';
import {DEFAULT_BY_BILLING_AND_SHIPPING} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";

export const initialState = {
  customer: {},
  addresses: null,
  creditCards: null,
  wishListItems: null,
  // TODO refactor all of these pages to use redux state only for global things...
  // TODO by now only wish list page is doing so
  pages: {
    myAccount: {
      loading: false,
      errors: []
    },
    password: {
      loading: false,
      errors: [],
      success: false
    },
    addressBook: {
      loading: false,
      errors: [],
      addressModalIsOpen: false,
      // the address selected to be edited in the modal
      selectedAddress: {},
      selectedShippingAddress: null,
      selectedBillingAddress: null
    },
    creditCards: {
      loading: false,
      errors: [],
      creditCardModalIsOpen: false,
      selectedCreditCard: {}
    }
  }
};

export const account = (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER_RECEIVE:
      return {
        ...state,
        customer: action.response.data
      };
    case types.CUSTOMER_UPDATE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          myAccount: {
            ...state.pages.myAccount,
            loading: true
          }
        }
      };
    case types.CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        customer: action.response.data,
        pages: {
          ...state.pages,
          myAccount: {
            ...state.pages.myAccount,
            loading: false,
            errors: []
          }
        }
      };
    case types.CUSTOMER_UPDATE_ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          myAccount: {
            ...state.pages.myAccount,
            loading: false,
            errors: action.messages
          }
        }
      };
    case types.PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          password: {
            ...state.pages.password,
            loading: true
          }
        }
      };
    case types.PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          password: {
            ...state.pages.password,
            loading: false,
            errors: [],
            success: true
          }
        }
      };
    case types.PASSWORD_UPDATE_ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          password: {
            ...state.pages.password,
            loading: false,
            errors: action.messages,
            success: false
          }
        }
      };
    case types.ADDRESSES_RECEIVE:
      return {
        ...state,
        addresses: action.response
      };
    case types.ADDRESS_CREATE_REQUEST:
    case types.ADDRESS_EDIT_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            loading: true
          }
        }
      };
    case types.ADDRESS_CREATE_ERROR:
    case types.ADDRESS_EDIT_ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            loading: false,
            errors: action.response.data
          }
        }
      };
    case types.ADDRESS_CREATE_SUCCESS:
      return {
        ...state,
        addresses: [
          ...state.addresses,
          action.response.data
        ],
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            loading: false,
            errors: [],
            addressModalIsOpen: false
          }
        }
      };
    case types.ADDRESS_EDIT_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.map(address => {
          if (address.id === action.data.id) {
            return action.response.data;
          }
          return address;
        }),
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            loading: false,
            errors: [],
            addressModalIsOpen: false
          }
        }
      };
    case types.ADDRESS_DELETE_REQUEST:
      return {
        ...state,
        addresses: state.addresses.map(address => {
          if (address.id === action.address.id) {
            address['deleting'] = true;
          }
          return address;
        })
      };
    case types.ADDRESS_DELETE_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.filter(address => address.id !== action.address.id)
      };
    case types.ADDRESS_MODAL_OPEN:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            addressModalIsOpen: true
          }
        }
      };
    case types.SELECT_BILLING_ADDRESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            selectedBillingAddress: action.addressBook
          }
        }
      };
    case types.SELECT_SHIPPING_ADDRESS:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            selectedShippingAddress: action.addressBook
          }
        }
      };
    case types.ADDRESS_MODAL_CLOSE:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            addressModalIsOpen: false,
            selectedAddress: {}
          }
        }
      };
    case types.ADDRESS_SELECT:
      return {
        ...state,
        pages: {
          ...state.pages,
          addressBook: {
            ...state.pages.addressBook,
            selectedAddress: action.address
          }
        }
      };
    case types.CREDIT_CARDS_RECEIVE:
      return {
        ...state,
        creditCards: action.response
      };
    case types.CREDIT_CARD_CREATE_REQUEST:
    case types.CREDIT_CARD_EDIT_REQUEST:
      return {
        ...state,
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            loading: true
          }
        }
      };
    case types.CREDIT_CARD_CREATE_ERROR:
    case types.CREDIT_CARD_EDIT_ERROR:
      return {
        ...state,
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            loading: false,
            errors: action.response.data
          }
        }
      };
    case types.CREDIT_CARD_CREATE_SUCCESS:
      return {
        ...state,
        creditCards: [
          ...state.creditCards,
          action.response.data
        ],
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            loading: false,
            errors: [],
            creditCardModalIsOpen: false
          }
        }
      };
    case types.CREDIT_CARD_EDIT_SUCCESS:
      return {
        ...state,
        creditCards: state.creditCards.map(creditCard => {
          if (creditCard.id === action.data.id) {
            return action.response.data;
          }
          return creditCard;
        }),
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            loading: false,
            errors: [],
            creditCardModalIsOpen: false
          }
        }
      };
    case types.CREDIT_CARD_DELETE_REQUEST:
      return {
        ...state,
        creditCards: state.creditCards.map(creditCard => {
          if (creditCard.id === action.creditCard.id) {
            creditCard['deleting'] = true;
          }
          return creditCard;
        })
      };
    case types.CREDIT_CARD_DELETE_SUCCESS:
      return {
        ...state,
        creditCards: state.creditCards.filter(creditCard => creditCard.id !== action.creditCard.id)
      };
    case types.CREDIT_CARD_SET_AS_DEFAULT:
      return {
        ...state,
        creditCards: state.creditCards.map(creditCard => {
          creditCard.default = creditCard.id === action.creditCard.id;
          return creditCard;
        })
      };
    case types.ADDRESS_BOOK_SET_AS_DEFAULT:
      return {
        ...state,
        addresses: state.addresses.map(addressBook => {
          if (addressBook.id === action.addressBook.id && action.addressBook.default) {
            addressBook.default = true;
            addressBook.address_type = action.addressBook.address_type;
          } else {
            if (addressBook.default && (
                    action.addressBook.address_type === DEFAULT_BY_BILLING_AND_SHIPPING
                    || action.addressBook.address_type === addressBook.address_type
                    || (action.addressBook.address_type !== DEFAULT_BY_BILLING_AND_SHIPPING
                        && addressBook.address_type === DEFAULT_BY_BILLING_AND_SHIPPING)
                ))
              addressBook.default = false;
          }

          return addressBook;
        })
      };
    case types.CREDIT_CARD_MODAL_OPEN:
      return {
        ...state,
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            creditCardModalIsOpen: true
          }
        }
      };
    case types.CREDIT_CARD_MODAL_CLOSE:
      return {
        ...state,
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            creditCardModalIsOpen: false,
            selectedCreditCard: {},
            errors: []
          }
        }
      };
    case types.CREDIT_CARD_SELECT:
      return {
        ...state,
        pages: {
          ...state.pages,
          creditCards: {
            ...state.pages.creditCards,
            selectedCreditCard: action.creditCard
          }
        }
      };
    case types.EMPTY_ACCOUNT_INFORMATION:
      return {
        ...state,
        creditCards: null,
        addresses: null,
        pages: {
          ...initialState.pages
        }
      };
    case types.WISH_LIST_ITEMS_RECEIVE:
      return {
        ...state,
        wishListItems: action.wishListItems
      };
    case types.WISH_LIST_ITEM_UPDATE:
      return {
        ...state,
        wishListItems: state.wishListItems.map(wishListItem => {
          if (wishListItem.id === action.wishListItem.id)
            return action.wishListItem;
          return wishListItem;
        })
      };
    case types.WISH_LIST_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        wishListItems: state.wishListItems.filter(wishListItem => wishListItem.id !== action.wishListItem.id)
      };
    case types.WISH_LIST_ITEM_ADD:
      return {
        ...state,
        wishListItems: [
            ...state.wishListItems,
            action.wishListItem
        ]
      };
    case types.WISH_LIST_ITEM_REMOVE:
      return {
        ...state,
        wishListItems: state.wishListItems.filter(wishListItem => wishListItem.id !== action.wishListItem.id)
      };
    default:
      return state;
  }
};