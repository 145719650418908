import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./styles/SelectedAddres.scss";

class SelectedAddress extends Component {
  render() {
    const {address} = this.props;

    return (
        <div className="select-address">
          <div className="select-address__icon">
            <i className="icon-Pin"/>
          </div>
          <div className="select-address__info">
            <ul>
              <li className="bold text-uppercase">
                {address.full_name}
              </li>
              <li>
                  {address.address_1}
              </li>
              <li>
                  {address.city_name}, {address.state_code} {address.zip_code}
              </li>
            </ul>
          </div>
          <div className="clearfix"/>
        </div>
    )
  }
}

SelectedAddress.propTypes = {
  address: PropTypes.object.isRequired
};

export default SelectedAddress
