import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {Link} from "react-router-dom";
import {renderInput} from "utils/forms/renderers";
import {email, required} from "utils/forms/validators";
import Recaptcha from "components/Recaptcha/index";
import withRecaptcha from "utils/components/withRecaptcha/index";

export class LoginForm extends Component {
  render() {
    const {isLoading, errors, refRecaptcha} = this.props;

    return (
        <form className="form" onSubmit={this.props.handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <Field id="email"
                   name="email"
                   component={renderInput}
                   type="email"
                   className="form-control"
                   validate={[required, email]}
                   placeholder="Type your email address"/>
          </div>
          <div className="form-group">
            <label htmlFor="password">Password <span className="required">*</span></label>
            <Field id="password"
                   name="password"
                   component={renderInput}
                   type="password"
                   className="form-control"
                   autoComplete="off"
                   validate={[required]}
                   placeholder="Type your password"/>
            {errors.map(error => (
                <span className="help-block">
                  {error}
                </span>
            ))}
          </div>
            <Recaptcha
                refRecaptcha={refRecaptcha}
                afterCheckedRecaptcha={this.props.afterCheckedRecaptcha}
                onExpiredRecaptcha={this.props.onExpiredRecaptcha}/>
          <br/>
          <div className="form-group">
              {
                  isLoading ? (
                      <input
                          type="submit"
                          value="LOGGING IN..."
                          disabled={true}/>
                  ) : (
                      <input
                          type="submit"
                          disabled={!this.props.checkedRecaptcha}
                          value="LOGIN"/>
                  )
              }
          </div>
          <div className="options">
            <p>
              <Link to="/auth/signup"><span className="green">Create an Account</span></Link>
              <span className="vline"/>
              <Link to="/auth/forgot-password"><span>Forgot Password?</span></Link>
            </p>
          </div>
        </form>
    )
  }
}

export default withRecaptcha(reduxForm({
    form: 'authLoginForm'
})(LoginForm));

