import React, {Component, Suspense, lazy} from 'react';
import "./styles/CheckoutPage.scss";
import CheckoutSummaryContainer from "modules/store/scenes/CheckoutPage/components/CheckoutSummary/container/index";
import CheckoutShippingAddressContainer
  from "modules/store/scenes/CheckoutPage/components/CheckoutShippingAddress/container/index";
import {apiList} from "services/api/index";
import {getStoredAddresses, getStoredCards} from "modules/store/scenes/CheckoutPage/services/storage/index";
import CheckoutShippingMethodContainer
  from "modules/store/scenes/CheckoutPage/components/CheckoutShippingMethod/container/index";
import {CREDIT_CARD_PAYMENT_METHOD} from "modules/store/scenes/CheckoutPage/contants/payment-methods";
import CheckoutPaymentMethodContainer
  from "modules/store/scenes/CheckoutPage/components/CheckoutPaymentMethod/container/index";
import {placeOrder} from "services/store/index";
import _ from "lodash";
import {Link, withRouter} from "react-router-dom";

const PayPalButton = lazy(() => import("./components/PayPalButton"))


class CheckoutPage extends Component {
  constructor() {
    super();

    this.state = {
      paymentMethod: CREDIT_CARD_PAYMENT_METHOD,
      cvvCode: "",
      placingOrder: false,
      productsWithoutStock: [],
      nonFieldErrors: []
    };

    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.handleCvvCodeInputChange = this.handleCvvCodeInputChange.bind(this);
    this.handlePlaceOrderButtonClick = this.handlePlaceOrderButtonClick.bind(this);
    this.onAfterPlaceOrder = this.onAfterPlaceOrder.bind(this);
  }

  componentDidMount() {
    this.fetchAddresses();
    this.fetchCreditCards();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated)
      this.fetchAddresses();
  }

  fetchAddresses() {
    const {addresses} = this.props.account;
    const {isAuthenticated} = this.props.auth;

    // Addresses already fetched, no need to re fetch them
    if (addresses && addresses.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated ?
        apiList('addresses')
        :
        Promise.resolve({data: { results: getStoredAddresses() }});
    promise.then(response => this.props.receiveAddresses(response.data.results));
    return promise;
  }

  fetchCreditCards() {
    const {creditCards} = this.props.account;
    const {isAuthenticated} = this.props.auth;

    // Addresses already fetched, no need to re fetch them
    if (!isAuthenticated && creditCards && creditCards.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated ?
        apiList('creditCards')
        :
        Promise.resolve({data: { results: getStoredCards() }});
    promise.then(response => this.props.receiveCreditCards(response.data.results));
    return promise;
  }

  handlePaymentMethodChange(paymentMethod) {
    this.setState({paymentMethod})
  }

  handleCvvCodeInputChange(event) {
    this.setState({cvvCode: event.target.value})
  }

  handlePlaceOrderButtonClick() {
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    const {cvvCode} = this.state;
    const selectedCreditCard = this.selectedCreditCard;

    this.setState({
      placingOrder: true
    }, () => {
      const orderData = {
        billingAddressId: selectedCreditCard.billingAddress.id,
        shippingAddressId: selectedShippingAddress.id,
        creditCardId: selectedCreditCard.id,
        cvv: cvvCode
      };

      const promise = placeOrder(orderData);
      promise.then(response => {
        this.onAfterPlaceOrder(response.data.uuid)
      }).catch(error => {
        this.setState({
          placingOrder: false
        });
        if (_.has(error, 'response.data')) {
          const {data} = error.response;
          if (_.has(data, 'products_without_stock')) {
            const products = data.products_without_stock.map(product => product.name);
            const {cart} = this.props.store;

            _.forEach(data.products_without_stock, product => {
              const item = _.find(cart.cart_items, item => item.id === product.cart_item_id);
              if (item)
                this.props.emptyCartItemStock(item);
            });

            this.setState({
              productsWithoutStock: products
            });
          }
          if (_.has(data, 'non_field_errors'))
            this.setState({
              nonFieldErrors: data.non_field_errors
            })
        }
      });
    })
  }

  onAfterPlaceOrder(orderUUID) {
    this.props.emptyCart();
    this.props.history.push({
      pathname: `/store/checkout/success/${orderUUID}`
    })
  }

  get selectedCreditCard() {
    const {creditCards, addresses} = this.props.account;
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    let selectedCreditCard = null;
    let billingAddress = null;

    if (creditCards && creditCards.length > 0) {
      selectedCreditCard = creditCards.find(creditCard => creditCard.default) || creditCards[0];

      if (selectedCreditCard.billing_address && addresses && addresses.length > 0) {
        billingAddress = addresses.find(address => address.id === selectedCreditCard.billing_address)
            || selectedShippingAddress
      }
    }

    return {
      ...selectedCreditCard,
      billingAddress
    };
  }

  render() {
    const {selectedShippingAddress} = this.props.account.pages.addressBook;
    const {paymentMethod, cvvCode, productsWithoutStock, placingOrder} = this.state;
    const {cart} = this.props.store;
    const {creditCards} = this.props.account;
    const selectedCreditCard = this.selectedCreditCard;

    const checkoutReady = !((creditCards && creditCards.length === 0)
        || !selectedCreditCard.billingAddress
        || !selectedShippingAddress
        || cart.cart_items.length === 0
        || cvvCode.length === 0); // Cart without items

    return (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="subtitle">
                  <h1>Checkout</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="alert alert-warning" role="alert" style={{textTransform: "none", marginBottom: 0}}>
              If purchasing multiple items, you might expect separate deliveries.
            </div>
          </div>
          <section className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-7 col-lg-8">
                  <div className="wrapper checkout checkout--steps">

                    {/*Checkout step: Shipping Address*/}
                    <CheckoutShippingAddressContainer/>

                    {/*Checkout step: Shipping Method*/}
                    <CheckoutShippingMethodContainer/>

                    {/*Checkout step: Payment Method*/}
                    <CheckoutPaymentMethodContainer
                        paymentMethod={paymentMethod}
                        onChangePaymentMethod={this.handlePaymentMethodChange}
                        cvvCode={cvvCode}
                        onCvvCodeInputChange={this.handleCvvCodeInputChange}/>

                    {
                      productsWithoutStock.length > 0 &&
                      <div className="checkout-stock-error">
                        {
                          productsWithoutStock.length === 1
                              ?
                              <p>Sorry! Looks like there's no stock for "{productsWithoutStock[0]}". Please edit your
                                cart before checking out.</p>
                              :
                              <p>
                                Sorry! The following products hasn't stock now:&nbsp;
                                {
                                  productsWithoutStock.map((product, index) => (
                                      <span key={index}>
                                        {product}
                                        {
                                          index + 1 !== productsWithoutStock.length &&
                                          <span>,&nbsp;</span>
                                        }
                                      </span>
                                  ))
                                }
                                . Please edit your cart before checking out.
                              </p>
                        }
                        <div className="row">
                          <div className="col-md-5 col-md-offset-7 col-sm-5">
                            <Link to="/store/cart" className="btn-custom" style={{
                              margin: "30px 0 0",
                              display: "block",
                              textTransform: "uppercase",
                              textAlign: "center"
                            }}>
                              <span className="span span--place_order">Back to Cart</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    }

                    {/*Checkout placer order*/}
                    {
                      productsWithoutStock.length === 0 &&
                      <div className="place-order">
                        {
                          this.state.nonFieldErrors.map((error, index) =>
                              <div
                                  key={index}
                                  className="alert alert-danger"
                                  role="alert"
                                  style={{textTransform: "none", textAlign: "left"}}>
                                <strong>Error!</strong> {error}
                              </div>
                          )
                        }
                        <div className="row">
                          <div className="col-md-5 col-md-offset-7 col-sm-5">
                            {
                              paymentMethod === CREDIT_CARD_PAYMENT_METHOD ? (
                                  <button
                                      className="btn-custom"
                                      disabled={!checkoutReady || placingOrder}
                                      onClick={this.handlePlaceOrderButtonClick}>
                                    <span className="span span--place_order">
                                      {
                                        placingOrder
                                            ?
                                            <span>PLACING...</span>
                                            :
                                            <span>PLACE ORDER</span>
                                      }
                                    </span>
                                  </button>
                              ) : (
                                  <Suspense fallback={<div>Loading...</div>}>
                                    <PayPalButton
                                        billing_address={selectedShippingAddress}
                                        shipping_address={selectedShippingAddress}
                                        receiveAndCompleteOrder={this.onAfterPlaceOrder}
                                        disabled={!selectedShippingAddress}/>
                                  </Suspense>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 col-lg-4">
                  <div className="wrapper checkout checkout--summary">
                    <CheckoutSummaryContainer
                        checkoutReady={checkoutReady}/>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

CheckoutPage.propTypes = {};

export default withRouter(CheckoutPage)
