import React from 'react';
import PropTypes from 'prop-types';

const InvoiceModalAddress = ({address}) => (
    <p>
      {address.address_1}<br/>
      {address.city_name}, {address.state_code}<br/>
      {address.zip_code}
    </p>
);

InvoiceModalAddress.propTypes = {
  address: PropTypes.object.isRequired
};

export default InvoiceModalAddress;