import React, {Component} from 'react';
import {Field} from 'redux-form';
import {renderCheckbox, renderInput, renderPhoneInput} from "utils/forms/renderers";
import {email, onlyText, required} from "utils/forms/validators";
import Select from "react-select";
import {apiList} from "services/api/index";
import PropTypes from "prop-types";
import {
  BILLING_FIELD,
  SHIPPING_FIELD
} from "modules/MyAccount/scenes/AddressBook/components/AddAddressModal/components/AddressFields/constants/index";
import {renderSelect} from "utils/forms/renderers";
import vintageAxios from "services/api/index";

export class AddressFields extends Component {
  constructor(props) {
    super(props);
    const {initialValues} = this.props;
    this.state = {
      selectedCity: '',
      cityPlaceholder: initialValues.id ? initialValues.city_name
          :
          'Select state, then input city here…',
      regions: [],
      selectedRegion: null
    };

    this.onChange = this.onChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.getCities = this.getCities.bind(this);
    this.fetchRegions = this.fetchRegions.bind(this);
  }

  componentDidMount() {
    this.fetchRegions();
  }

  /**
   * Fetches locations from the API in order to render the select
   */
  fetchRegions() {
    const promise = vintageAxios.get('/misc/region-list/');
    promise.then(response => {
      const selectedRegion = response.data.find(item => item.code === this.props.initialValues.region_name);
      this.setState({
        regions: response.data,
        selectedRegion: selectedRegion ? selectedRegion.id : null
      });
      if (selectedRegion)
        this.props.changeFieldValue('state', selectedRegion.id);
    });
    return promise;
  }

  onChange(city) {
    this.setState({
      selectedCity: city
    });
    this.props.changeFieldValue('city_name', city ? city.name: null);
  }

  handleRegionChange(event) {
    this.setState({
      selectedRegion: event.target.value,
      selectedCity: ""
    });
    this.props.changeFieldValue('city_name', null);
    if (this.refs.cityField)
      this.refs.cityField.loadOptions("")
  }

  getCities(input) {
    const {selectedRegion} = this.state;

    if (!input || (!selectedRegion || !this.props.initialValues)) {
      return Promise.resolve({options: []});
    }

    let queryParams = {
      'search': input,
      'state': selectedRegion ? selectedRegion : this.props.initialValues.state,
    };
    return apiList('cities', queryParams)
        .then((response) => {
          return {options: response.data}
        });
  }

  /**
   * Removes phone number format
   *
   * @param phoneNumber
   * @return {*}
   */
  static parsePhoneNumberFormat(phoneNumber) {
    return phoneNumber.replace(/[ ()-]/g, "");
  }

  render() {
    const {errors, removeOptions, initialValues} = this.props;
    const {isAuthenticated} = this.props.auth;
    const {regions, selectedRegion} = this.state;

    return (
        <div>
          <div className="row">
            {
              !removeOptions &&
              <div className="col-md-12">
                <h2><span className="circle">1</span><span>Shipping Address</span></h2>
              </div>
            }
            <div className="clearfix"/>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="full_name">Full name <span className="required">*</span></label>
                <Field id="full_name"
                       name="full_name"
                       autoComplete={"name"}
                       component={renderInput}
                       type="text"
                       className="form-control"
                       placeholder="Enter your first name"
                       validate={[required, onlyText]}/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address_1">Address Line 1 <span className="required">*</span></label>
                <Field id="address_1"
                       name="address_1"
                       component={renderInput}
                       type="text"
                       className="form-control"
                       autoComplete="address-line1"
                       placeholder="Enter your address line 1"
                       validate={[required]}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address_2">Address Line 2</label>
                <Field id="address_2"
                       name="address_2"
                       component={renderInput}
                       autoComplete="address-line2"
                       type="text"
                       className="form-control"
                       placeholder="Enter your address line 2"/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="state">State <span className="required">*</span></label>
                <Field
                    name="state"
                    id="state"
                    component={renderSelect}
                    className="form-control"
                    validate={[required]}
                    autoComplete="off"
                    onChange={this.handleRegionChange}
                    disabled={regions.length === 0}>
                  <option value="">{regions.length === 0 ? 'Loading states...' : 'Select a state'}</option>
                  {
                    regions.map(region =>
                        <option
                            key={`region${region.id}`}
                            value={region.id}>
                          {region.name}
                        </option>
                    )
                  }
                </Field>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="city">City <span className="required">*</span></label>
                <Select.Async
                    ref="cityField"
                    multi={false}
                    name="city_autocomplete"
                    value={this.state.selectedCity}
                    onChange={this.onChange}
                    valueKey="id"
                    aria-label={"city"}
                    inputProps={{autoComplete: 'off', autoCorrect: 'off', spellCheck: 'off'}}
                    placeholder={this.state.cityPlaceholder}
                    labelKey="name"
                    loadOptions={this.getCities}
                    disabled={!selectedRegion && !initialValues.state_name}
                    style={{ width: "300px", border: "1px solid #ccc" }}/>
                <Field
                    id="city_name"
                    name="city_name"
                    component={renderInput}
                    type="number"
                    autoComplete="off"
                    className="form-control hidden"
                    validate={[required]}
                    form="accountCreditCardForm"/>
              </div>
            </div>
            <div className="clearfix"/>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="zip_code">Zip Code <span className="required">*</span></label>
                <Field id="zip_code"
                       name="zip_code"
                       component={renderInput}
                       autoComplete="postal-code"
                       type="number"
                       className="form-control"
                       placeholder="Enter your zip code"
                       validate={[required]}/>
                {errors["non_field_errors"] && errors["non_field_errors"].map((msj, index) => (
                    <span className="help-block" key={`addressFormZipcodeError${index}`}>{msj}</span>
                ))}
              </div>
            </div>
            {
              !isAuthenticated &&
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email">Email <span className="required">*</span></label>
                  <Field id="email"
                         name="email"
                         component={renderInput}
                         type="email"
                         autoComplete="email"
                         className="form-control"
                         validate={[required, email]}
                         placeholder="Type your email address"/>
                  {errors.email && errors.email.map((msj, index) => (
                      <span className="help-block" key={`addressFormEmailError${index}`}>{msj}</span>
                  ))}
                </div>
              </div>
            }
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone">Phone <span className="required">*</span></label>
                <Field id="phone"
                       name="phone"
                       component={renderPhoneInput}
                       autoComplete="phone"
                       parse={value => AddressFields.parsePhoneNumberFormat(value)}
                       className="form-control"
                       validate={[required]}
                       placeholder="Enter your phone"/>
                {errors.phone && errors.phone.map((msj, index) => (
                    <span className="help-block" key={`addressFormEmailError${index}`}>{msj}</span>
                ))}
              </div>
            </div>
          </div>
          {
            !removeOptions &&
            <div className="row">
              <div className="col-sm-6">
                <div className="custom-checkbox">
                  <Field
                      component={renderCheckbox}
                      id={BILLING_FIELD}
                      name={BILLING_FIELD}
                      defaultChecked={!!(initialValues && initialValues[BILLING_FIELD])}/>
                </div>
                <label
                    className="address-form__default"
                    htmlFor={BILLING_FIELD}>Use as default for billing</label>
              </div>
              <div className="col-sm-6">
                <div className="custom-checkbox">
                  <Field
                      component={renderCheckbox}
                      id={SHIPPING_FIELD}
                      name={SHIPPING_FIELD}
                      defaultChecked={!!(initialValues && initialValues[SHIPPING_FIELD])}/>
                </div>
                <label
                    className="address-form__default"
                    htmlFor={SHIPPING_FIELD}>Use as default for shipping</label>
              </div>
            </div>
          }
        </div>
    )
  }
}


AddressFields.defaultProps = {
  removeOptions: false
};

AddressFields.propTypes = {
  removeOptions: PropTypes.bool
};

export default AddressFields