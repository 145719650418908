/* global dataLayer */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {updateCartItemQuantity} from "services/store";
import debounce from 'lodash/debounce';
import {NEW, USED} from "modules/store/scenes/ProductsList/components/Product/constants/conditions";
import {CART_ITEM_IN_HEADER, CART_ITEM_IN_CART, CART_ITEM_IN_CHECKOUT} from "./constants/contexts";
import "./styles/CartItem.scss";
import CartItemCondition from "./components/CartItemCondition";
import QuantityInput from "utils/components/QuantityInput/index";
import {CART_ITEM} from "modules/store/scenes/ProductDetails/components/AddToWishListButton/constants/index";
import AddToWishListButton from "modules/store/scenes/ProductDetails/components/AddToWishListButton/index";
import {PROMOTION_DISCOUNT, PROMOTION_FREE_PRODUCT} from "modules/store/scenes/Cart/constants/promotions";
import moment from "moment";
import Moment from "react-moment";

class CartItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      quantity: this.props.item.quantity
    };

    this.onQuantityChanges = this.onQuantityChanges.bind(this);
    this.deleteCartItem = this.deleteCartItem.bind(this);
  }

  /**
   * Deletes an item from the cart through the API
   */
  deleteCartItem() {
    this.setState({deleting: true}, () => {
      let promise = this.props.deleteCartItem(this.props.item);
      promise.catch(error => {
        this.setState({deleting: false});
      });

      const {item} = this.props;
      const {quantity} = this.state;
      dataLayer.push({
        "event": "productRemovedFromCart",
        "ecommerce": {
          "currencyCode": "USD",
          "remove": {
            "products": [{
              "id": item.product_sku,
              "name": item.product_name,
              "quantity": quantity
            }]
          }
        }
      });
    });
  }

  /**
   * Updates item quantity after it changes
   */
  updateItemQuantity() {
    const {item} = this.props;
    const {quantity} = this.state;
    if (quantity) {
      updateCartItemQuantity(item, quantity);
      this.props.updateItemQuantity(item, quantity);
    }
  }

  debounceUpdateItemQuantity = debounce(this.updateItemQuantity, 500);

  /**
   * Handles change event on the quantity input
   * @param {Number} quantity
   */
  onQuantityChanges(quantity) {
    this.setState({
      quantity: quantity
    }, () => {
      this.debounceUpdateItemQuantity();
    });
  }

  /**
   * Returns the promotion for this cart item or undefined if nothing found
   * @returns {*}
   */
  get promotion() {
    const {object_id} = this.props.item;
    const {cartTotals} = this.props.store;

    if (cartTotals.products_with_discount === undefined)
      return undefined;

    if (cartTotals.products_with_discount.length === 0)
      return undefined;

    return cartTotals.products_with_discount.find(discount => discount.product.id === object_id);
  }

  render() {
    const {item, context} = this.props;
    const soldOut = !item.is_pre_order &&                                   // should not be pre-order
        ((item.condition === NEW && item.product_total_stock_new === 0) ||  // new but no more new stock
            (item.condition === USED && item.product_total_stock_used === 0));  // used but no more used stock

    return (
        <div className={classNames({
          "clearfix cart-item": true,
          "cart-item--in-header": context === CART_ITEM_IN_HEADER,
          "cart-item--in-checkout": context === CART_ITEM_IN_CHECKOUT
        })}>
          <div className="photo">
            <img src={item.cart_image_thumbnail} alt={item.product_name}/>
          </div>
          <div className="info">
            <p className="info__name">
              {item.product_name}
            </p>
            {
              moment(item.release_date) > moment(new Date()) &&
              <p className="product-card-info__release-date">
                Release date:&nbsp;
                <Moment format="MMM D, YYYY">
                  {item.release_date}
                </Moment>
              </p>
            }
            <p className="info__details">
              <span className={classNames({
                "item-price": true,
                "item-price--new": item.condition === NEW,
                "item-price--used": item.condition === USED
              })}>
                ${item.condition === NEW ? item.product_price_new : item.product_price_used}
              </span>
              <CartItemCondition condition={item.condition}/>
              {
                [CART_ITEM_IN_HEADER, CART_ITEM_IN_CHECKOUT].indexOf(context) > -1 &&
                <span className="item-quantity">x{item.quantity}</span>
              }
              {
                item.is_pre_order &&
                <span className="pre-order-label">PRE-ORDER</span>
              }
              {
                soldOut &&
                <span className="sold-out-label">SOLD OUT</span>
              }
              {
                this.promotion &&
                <span className="item-promo text-uppercase">
                  <span className="item-promo__label label label-danger">
                    {
                      this.promotion.quantity !== this.state.quantity &&
                      <small>{this.promotion.quantity}&nbsp;</small>
                    }
                    {
                      this.promotion.promotion_type === PROMOTION_DISCOUNT ?
                          this.promotion.promotion
                          :
                          "FREE"
                    }
                  </span>
                  <span className="item-promo__description">
                    {
                      this.promotion.promotion_type === PROMOTION_FREE_PRODUCT &&
                      this.promotion.promotion
                    }
                  </span>
                </span>
              }
            </p>
            {
              (context === CART_ITEM_IN_CART && item.condition === USED) && (
                  <p>
                    <span className="q-icon icon-circle-check"/>
                    <span className="quality-guaranteed">100% Quality Guaranteed</span>
                  </p>
              )
            }
          </div>
          {
            context === CART_ITEM_IN_CART && (
                <div className="right">
                  <div className="input">
                    <label htmlFor={`quantity_item_${item.id}`}>Qty:</label>
                    <QuantityInput
                        quantity={soldOut ? 0 : this.state.quantity}
                        label={item.name}
                        id={`quantity_item_${item.id}`}
                        condition={item.condition}
                        stockNew={item.product_total_stock_new}
                        stockUsed={item.product_total_stock_used}
                        isPreOrder={item.is_pre_order}
                        onQuantityChanges={this.onQuantityChanges}
                        disabled={soldOut}/>
                  </div>
                  <div className="add-to-wish-list">
                    <AddToWishListButton
                        contentType={item.content_type}
                        productId={item.object_id}
                        selectedCondition={item.condition}
                        context={CART_ITEM}
                        onAfterAddToWishList={this.deleteCartItem}
                    />
                  </div>
                  <div className="options">
                    <button
                        onClick={this.deleteCartItem}
                        disabled={this.state.deleting}
                        aria-label={`Remove ${item.name} from cart`}
                        className="un-styled">
                      <span className="icon-rubbish"/>
                    </button>
                  </div>
                </div>
            )
          }
          {
            context === CART_ITEM_IN_HEADER && (
                <button
                    type="button"
                    className="cart-item__delete-button"
                    onClick={this.deleteCartItem}
                    disabled={this.state.deleting}>
                  <i>x</i>
                </button>
            )
          }
        </div>
    )
  }
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  context: function (props, propName, componentName) {
    // At least by now this button could live in ProductDetails and WishList components
    const availableContexts = [
      CART_ITEM_IN_CART,
      CART_ITEM_IN_HEADER,
      CART_ITEM_IN_CHECKOUT
    ];
    if (!availableContexts.includes(props[propName])) {
      return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. Validation failed.`
      );
    }
  },
};

export default CartItem
