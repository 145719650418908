import axios from 'axios';
import * as queryString from "query-string";

export const getBaseURL = () => {
  return process.env.REACT_APP_API_URL;
};

const config = {
  baseURL: getBaseURL(),
  timeout: 200000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: function(params) {
    return queryString.stringify(params)
  }
};

const vintageAxios = axios.create(config);

vintageAxios.interceptors.request.use((config) => {
  // Temporarily commented the use of LOCAL_STORAGE_TOKEN_KEY constant to avoid having another dependence in my bit.dev migration
  // let token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  let token = localStorage.getItem("vintageStockAuthToken");
  if( token ){
    config.headers = {
      ...config.headers,
      'Authorization': `Token ${token}`
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

let resources = [
  {
    name: 'siteSliders',
    url: '/misc/site-sliders/',
    allowedMethods: ['get']
  },
  {
    name: 'customer',
    url: '/management/customers/',
    allowedMethods: ['get']
  },
  {
    name: 'addresses',
    url: '/v2/management/customers/addressess/',
    allowedMethods: ['get', 'post', 'delete', 'patch']
  },
  {
    name: 'creditCards',
    url: '/v2/management/customers/credit-cards/',
    allowedMethods: ['get', 'post', 'delete', 'patch']
  },
  {
    name: 'cities',
    url: '/misc/city-list/',
    allowedMethods: ['get']
  },
  {
    name: 'contactMessage',
    url: '/misc/contact-us/',
    allowedMethods: ['post']
  },
  {
    name: 'products',
    url: '/products/all/',
    allowedMethods: ['get']
  },
  {
    name: 'locations',
    url: '/misc/locations/',
    allowedMethods: ['get']
  },
  {
    name: 'siteBanners',
    url: '/misc/site-banners/',
    allowedMethods: ['get']
  },
  {
    name: 'sitePromos',
    url: '/misc/site-promos/',
    allowedMethods: ['get']
  },
  {
    name: 'orders',
    url: '/management/orders/',
    allowedMethods: ['get']
  },
];

const getUrlByName = (name) => {
  let found = resources.find((resource) => {
    return resource.name === name;
  });
  // TODO validate the case when the provided name is not in the resources array
  return found.url;
};

export function apiGet({resourceName, id=null, params={}}){
  let url = getUrlByName(resourceName);
  if( id ){
    url = `${url}${id}/`
  }
  return vintageAxios.get(url, {
    params: params
  })
}

export function apiList(resourceName, params){
  let url = getUrlByName(resourceName);
  return vintageAxios.get(url, {
    params: params
  })
}

export function apiCreate(resourceName, data, params){
  let url = getUrlByName(resourceName);
  return vintageAxios.post(url, data, {
    params: params
  })
}

export function apiEdit({resourceName, id=null, data, params={}}){
  let url = getUrlByName(resourceName);
  if( id ){
    url = `${url}${id}/`;
  }

  return vintageAxios.put(url, data, {
    params: params
  })
}

export function apiDelete(resourceName, id){
  let url = getUrlByName(resourceName);
  url = `${url}${id}/`;
  return vintageAxios.delete(url)
}

export function apiPatch({resourceName, id=null, data, params={}}){
  let url = getUrlByName(resourceName);
  if( id ){
    url = `${url}${id}/`;
  }

  return vintageAxios.patch(url, data, {
    params: params
  })
}


export default vintageAxios;