import * as types from './types';
import {apiCreate, apiDelete, apiEdit, apiPatch} from "services/api";
import {changePassword} from "services/auth";
import {removeFromWishList} from "services/store/wishlist";
import {
  saveAddressInLocalStorage,
  saveCreditCardInLocalStorage
} from "modules/store/scenes/CheckoutPage/services/storage";
import Address from "modules/MyAccount/scenes/AddressBook/components/Address/index";
import {
  DEFAULT_BY_BILLING,
  DEFAULT_BY_BILLING_AND_SHIPPING,
  DEFAULT_BY_SHIPPING
} from "modules/MyAccount/scenes/AddressBook/components/Address/constants";
import CreditCard from "modules/MyAccount/scenes/CreditCardsInfo/components/CreditCard/index";

export const receiveCustomer = (response) => ({
  type: types.CUSTOMER_RECEIVE,
  response
});

export const updateCustomerRequest = (data) => ({
  type: types.CUSTOMER_UPDATE_REQUEST,
  data
});

export const updateCustomerSuccess = (data, response) => ({
  type: types.CUSTOMER_UPDATE_SUCCESS,
  data,
  response
});

export const updateCustomerFailed = (data, messages) => ({
  type: types.CUSTOMER_UPDATE_ERROR,
  data,
  messages
});

export const addOrUpdateAddress = address => ({
  type: types.ADDRESS_ADD_OR_UPDATE,
  address
});

export function updateCustomer(data) {
  return (dispatch) => {
    dispatch(updateCustomerRequest(data));
    return apiEdit({resourceName: 'customer', data: data})
        .then(response => dispatch(updateCustomerSuccess(data, response)))
        .catch((error) => {
          let messages = error.response ? error.response.data.non_field_errors : [];
          dispatch(
              updateCustomerFailed(data, messages)
          )
        });
  };
}

export const updatePasswordRequest = (data) => ({
  type: types.PASSWORD_UPDATE_REQUEST,
  data
});

export const updatePasswordSuccess = (data, response) => ({
  type: types.PASSWORD_UPDATE_SUCCESS,
  data,
  response
});

export const updatePasswordFailed = (data, messages) => ({
  type: types.PASSWORD_UPDATE_ERROR,
  data,
  messages
});

export function updatePassword(data) {
  return (dispatch) => {
    dispatch(updatePasswordRequest(data));
    return changePassword(data)
        .then(function (response) {
          dispatch(
              updatePasswordSuccess(data, response)
          );
        })
        .catch((error) => {
          let messages = error.response.data;
          dispatch(
              updatePasswordFailed(data, messages)
          )
        });
  };
}

export const receiveAddresses = (response) => ({
  type: types.ADDRESSES_RECEIVE,
  response
});

export const createAddressRequest = (data) => ({
  type: types.ADDRESS_CREATE_REQUEST,
  data
});

export const createAddressSuccess = (data, response) => ({
  type: types.ADDRESS_CREATE_SUCCESS,
  data,
  response
});

export const createAddressFailed = (data, response) => ({
  type: types.ADDRESS_CREATE_ERROR,
  data,
  response
});

export function createAddress(data) {
  return (dispatch) => {
    dispatch(createAddressRequest(data));
    const promise = apiCreate('addresses', data)
        .then(response => {
          let promiseAddressByDefault = null;
          if (data.byDefaultAsBilling && data.byDefaultAsShipping) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_BILLING_AND_SHIPPING);
            dispatch(selectBillingAddress(response.data));
            dispatch(selectShippingAddress(response.data));
          } else if (data.byDefaultAsBilling) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_BILLING);
            dispatch(selectBillingAddress(response.data));
          } else if (data.byDefaultAsShipping) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_SHIPPING);
            dispatch(selectShippingAddress(response.data));
          }

          if (promiseAddressByDefault)
            promiseAddressByDefault.then(response => {
              dispatch(setAddressBookAsDefault(response.data));
              saveAddressInLocalStorage(response.data);
            });
          else
            saveAddressInLocalStorage(response.data);

          dispatch(createAddressSuccess(data, response));

          return response;
        })
        .catch(error => {
          dispatch(createAddressFailed(data, error.response));
        });

    return promise;
  };
}

export const editAddressRequest = (data) => ({
  type: types.ADDRESS_EDIT_REQUEST,
  data
});

export const editAddressSuccess = (data, response) => ({
  type: types.ADDRESS_EDIT_SUCCESS,
  data,
  response
});

export const editAddressFailed = (data, response) => ({
  type: types.ADDRESS_EDIT_ERROR,
  data,
  response
});

export function editAddress(data) {
  return (dispatch) => {
    dispatch(editAddressRequest(data));
    return apiEdit({resourceName: 'addresses', id: data.uuid, data: data})
        .then(function (response) {

          dispatch(editAddressSuccess(data, response));

          let promiseAddressByDefault = null;
          if (data.byDefaultAsBilling && data.byDefaultAsShipping) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_BILLING_AND_SHIPPING);
            dispatch(selectBillingAddress(response.data));
            dispatch(selectShippingAddress(response.data));
          } else if (data.byDefaultAsBilling) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_BILLING);
            dispatch(selectBillingAddress(response.data));
          } else if (data.byDefaultAsShipping) {
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, DEFAULT_BY_SHIPPING);
            dispatch(selectShippingAddress(response.data));
          } else
            promiseAddressByDefault = Address.setAddressAsDefault(response.data, null, false);

          promiseAddressByDefault.then(response => {
            saveAddressInLocalStorage(response.data);
            dispatch(setAddressBookAsDefault(response.data));
          });
        })
        .catch((error) => {
          dispatch(
              editAddressFailed(data, error.response)
          )
        });
  };
}

export const deleteAddressRequest = (address) => ({
  type: types.ADDRESS_DELETE_REQUEST,
  address
});

export const deleteAddressSuccess = (address, response) => ({
  type: types.ADDRESS_DELETE_SUCCESS,
  address,
  response
});

export const deleteAddressFailed = (address, response) => ({
  type: types.ADDRESS_DELETE_ERROR,
  address,
  response
});

export function deleteAddress(address) {
  return (dispatch) => {
    dispatch(deleteAddressRequest(address));
    return apiDelete('addresses', address.uuid)
        .then(function (response) {
          dispatch(
              deleteAddressSuccess(address, response)
          );
        })
        .catch((error) => {
          dispatch(
              deleteAddressFailed(address, error.response)
          )
        });
  };
}

export const selectAddress = (address) => ({
  type: types.ADDRESS_SELECT,
  address
});

export const openAddressModal = () => ({
  type: types.ADDRESS_MODAL_OPEN
});

export const closeAddressModal = () => ({
  type: types.ADDRESS_MODAL_CLOSE
});

export const receiveCreditCards = (response) => ({
  type: types.CREDIT_CARDS_RECEIVE,
  response
});

export const createCreditCardRequest = (data) => ({
  type: types.CREDIT_CARD_CREATE_REQUEST,
  data
});

export const createCreditCardSuccess = (data, response) => ({
  type: types.CREDIT_CARD_CREATE_SUCCESS,
  data,
  response
});

export const createCreditCardFailed = (data, response) => ({
  type: types.CREDIT_CARD_CREATE_ERROR,
  data,
  response
});

export function createCreditCard(data) {
  return (dispatch) => {
    dispatch(createCreditCardRequest(data));
    return apiCreate('creditCards', data)
        .then(response => {
          if (data.byDefault) {
            let promiseCreditCardAsDefault = CreditCard.setCreditCardAsDefault(response.data);

            promiseCreditCardAsDefault.then(creditCard => {
              dispatch(setCreditCardAsDefault(creditCard.data));
            });
          }

          saveCreditCardInLocalStorage(response.data);
          dispatch(createCreditCardSuccess(data, response));
        })
        .catch(error => {
          dispatch(createCreditCardFailed(data, error.response));
        });
  };
}

export const editCreditCardRequest = (data) => ({
  type: types.CREDIT_CARD_EDIT_REQUEST,
  data
});

export const editCreditCardSuccess = (data, response) => ({
  type: types.CREDIT_CARD_EDIT_SUCCESS,
  data,
  response
});

export const editCreditCardFailed = (data, response) => ({
  type: types.CREDIT_CARD_EDIT_ERROR,
  data,
  response
});

export function editCreditCard(data) {
  return (dispatch) => {
    dispatch(editCreditCardRequest(data));
    return apiEdit({resourceName: 'creditCards', id: data.uuid, data: data})
        .then(function (response) {
          dispatch(
              editCreditCardSuccess(data, response)
          );
        })
        .catch((error) => {
          dispatch(
              editCreditCardFailed(data, error.response)
          )
        });
  };
}

export const deleteCreditCardRequest = (creditCard) => ({
  type: types.CREDIT_CARD_DELETE_REQUEST,
  creditCard
});

export const deleteCreditCardSuccess = (creditCard, response) => ({
  type: types.CREDIT_CARD_DELETE_SUCCESS,
  creditCard,
  response
});

export const deleteCreditCardFailed = (creditCard, response) => ({
  type: types.CREDIT_CARD_DELETE_ERROR,
  creditCard,
  response
});

export function deleteCreditCard(creditCard) {
  return (dispatch) => {
    dispatch(deleteCreditCardRequest(creditCard));
    return apiDelete('creditCards', creditCard.uuid)
        .then(function (response) {
          dispatch(
              deleteCreditCardSuccess(creditCard, response)
          );
        })
        .catch((error) => {
          dispatch(
              deleteCreditCardFailed(creditCard, error.response)
          )
        });
  };
}

export const setCreditCardAsDefault = creditCard => ({
  type: types.CREDIT_CARD_SET_AS_DEFAULT,
  creditCard
});

export const setAddressBookAsDefault = addressBook => ({
  type: types.ADDRESS_BOOK_SET_AS_DEFAULT,
  addressBook
});

export const selectShippingAddress = addressBook => ({
  type: types.SELECT_SHIPPING_ADDRESS,
  addressBook
});

export const selectBillingAddress = addressBook => ({
  type: types.SELECT_BILLING_ADDRESS,
  addressBook
});

export const selectCreditCard = (creditCard) => ({
  type: types.CREDIT_CARD_SELECT,
  creditCard
});

export const openCreditCardModal = () => ({
  type: types.CREDIT_CARD_MODAL_OPEN
});

export const closeCreditCardModal = () => ({
  type: types.CREDIT_CARD_MODAL_CLOSE
});

export const receiveWishListItems = wishListItems => ({
  type: types.WISH_LIST_ITEMS_RECEIVE,
  wishListItems
});

export const updateWishListItem = wishListItem => ({
  type: types.WISH_LIST_ITEM_UPDATE,
  wishListItem
});

export const deleteWishListItemRequest = (wishListItem) => ({
  type: types.WISH_LIST_ITEM_DELETE_REQUEST,
  wishListItem
});

export const deleteWishListItemSuccess = (wishListItem, response) => ({
  type: types.WISH_LIST_ITEM_DELETE_SUCCESS,
  wishListItem,
  response
});

export const deleteWishListItemFailed = (wishListItem, error) => ({
  type: types.WISH_LIST_ITEM_DELETE_ERROR,
  wishListItem,
  error
});

export const addWishListItem = wishListItem => ({
  type: types.WISH_LIST_ITEM_ADD,
  wishListItem
})

export const removeWishListItem = wishListItem => ({
  type: types.WISH_LIST_ITEM_REMOVE,
  wishListItem
})

export const emptyAccountInformation = () => ({
  type: types.EMPTY_ACCOUNT_INFORMATION
});

export function deleteWishListItem(wishListItem) {
  return (dispatch) => {
    dispatch(deleteWishListItemRequest());
    return removeFromWishList(wishListItem)
        .then(response => dispatch(deleteWishListItemSuccess(wishListItem, response)))
        .catch(error => dispatch(deleteWishListItemFailed(wishListItem, error)));
  };
}