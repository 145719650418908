import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CreditCardFormContainer from "./components/CreditCardForm/container";
import {Modal} from "react-bootstrap";
import {
  CHECKOUT,
  MY_ACCOUNT
} from "modules/MyAccount/scenes/CreditCardsInfo/components/AddCreditCardModal/constants/index";
import {apiList} from "services/api/index";
import {isAuthenticated} from "services/auth/index";
import {getStoredAddresses} from "modules/store/scenes/CheckoutPage/services/storage/index";
import {DEFAULT_BY_SHIPPING} from "modules/MyAccount/scenes/AddressBook/components/Address/constants/index";

class AddCreditCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressFormIsOpen: false,
      addressErrors: [],
      selectedAddressId: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpenAddressForm = this.handleOpenAddressForm.bind(this);
    this.handleCloseAddressForm = this.handleCloseAddressForm.bind(this);
    this.changeSelectedAddressId = this.changeSelectedAddressId.bind(this);
  }

  componentDidMount() {
    this.fetchAddresses();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account.pages.creditCards.creditCardModalIsOpen
        !== this.props.account.pages.creditCards.creditCardModalIsOpen) {
      if (prevProps.account.pages.creditCards.creditCardModalIsOpen)
        this.setState({
          addressFormIsOpen: false // Address form is hide when credit card modal is close
        }, () => {
          this.reinitializeCreditCardForm();
        });
    }
  }

  /**
   * Reinitialize credit card form
   */
  reinitializeCreditCardForm() {
    // Reset credit card form
    this.props.resetCreditCardForm();
    // Clear errors of address form
    this.props.createAddressFailed([], {data: []});
  }

  /**
   * Reinitialize address form
   */
  reinitializeAddressForm() {
    // Reset address form
    this.props.resetAddressForm();
    // Clear errors of address form
    this.props.createAddressFailed([], {data: []});
  }

  /**
   * Open address form
   */
  handleOpenAddressForm() {
    this.setState({
      addressFormIsOpen: true
    }, () => {
      this.reinitializeAddressForm();
    });
  }

  /**
   * Close address form
   */
  handleCloseAddressForm() {
    this.setState({
      addressFormIsOpen: false
    });
  }

  fetchAddresses() {
    const {addresses} = this.props.account;

    // Addresses already fetched, no need to re fetch them
    if (addresses && addresses.length > 0)
      return Promise.resolve({data: []});

    // Simulate promise returning empty array when user is not authenticated
    const promise = isAuthenticated() ?
        apiList('addresses')
        :
        Promise.resolve({data: getStoredAddresses()});
    promise.then(response => {
      let selectedShippingAddress = null;
      if (response.data.result && response.data.results.length > 0)
        selectedShippingAddress = response.data.results.find(address => address.default && address.address_type === DEFAULT_BY_SHIPPING)
            // otherwise select the first one
            || response.data.results[0];

      this.props.receiveAddresses(response.data.results)

      this.setState({
        selectedAddressId: selectedShippingAddress ? selectedShippingAddress.id : null
      });
     
    });
    return promise;
  }

  /**
   * Change selected address Id
   * @param addressId
   */
  changeSelectedAddressId(addressId) {
    setTimeout(() => {
      this.props.changeFieldValue('billing_address', addressId);
    }, 0);
    this.setState({
      selectedAddressId: addressId
    });
  }

  /**
   * Handle submit credit card form
   * @param {Object} data
   */
  handleSubmit(data) {
    const {addressFormIsOpen} = this.state;
    const {createAddress} = this.props;

    let creditCard = Object.assign({}, data);
    delete creditCard["address"];

    // The billing address is created. Is added when modal is open
    if (addressFormIsOpen && data["address"]) {
      let promiseAddress;
      promiseAddress = createAddress(data["address"]);
      promiseAddress.then((response) => {
        this.changeSelectedAddressId(response.data.id);
        this.setState({
          addressFormIsOpen: false, // Hide address form why was added billing address
        });
        creditCard.billing_address = response.data.id;
        this.saveCreditCard(creditCard);
      }).catch(err => {
        console.log(err);
      });
    } else {
      // The billing address in creditCard object
      this.saveCreditCard(creditCard);
    }
  }

  /**
   * Save credit card information
   * @param {Object} data
   */
  saveCreditCard(data) {
    const {editCreditCard, createCreditCard} = this.props;
    let promiseCreditCard;

    if (data.uuid)  // edit mode
      promiseCreditCard = editCreditCard(data);
    else  // create mode
      promiseCreditCard = createCreditCard(data);

    promiseCreditCard.then(() => {
      if (typeof this.props.onAfterCreated === 'function')
        this.props.onAfterCreated();
    });
  }

  render() {
    const {addressFormIsOpen, selectedAddressId} = this.state;
    const {account, closeCreditCardModal, selectedCreditCard} = this.props;

    return (
        <Modal
            show={account.pages.creditCards.creditCardModalIsOpen}
            onHide={closeCreditCardModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {
                selectedCreditCard && selectedCreditCard.id
                    ? "Edit Credit Card"
                    : "Add Credit Card"
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreditCardFormContainer
                onSubmit={this.handleSubmit}
                isLoading={account.pages.creditCards.loading}
                errors={account.pages.creditCards.errors}
                addressErrors={account.pages.addressBook.errors}
                addressFormIsOpen={addressFormIsOpen}
                handleOpenAddressForm={this.handleOpenAddressForm}
                handleCloseAddressForm={this.handleCloseAddressForm}
                changeSelectedAddressId={this.changeSelectedAddressId}
                selectedAddressId={selectedAddressId}/>
          </Modal.Body>
        </Modal>
    )
  }
}

AddCreditCardModal.defaultProps = {
  context: CHECKOUT
};

AddCreditCardModal.propTypes = {
  context: PropTypes.oneOf([MY_ACCOUNT, CHECKOUT])
};

AddCreditCardModal.propTypes = {
  onAfterCreated: PropTypes.func
};

export default AddCreditCardModal